import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export const activeStyle: React.CSSProperties = {
  textDecoration: "underline",
  textUnderlineOffset: "5px",
  borderBottom: "5px solid orange",
};
export const navLinks = [
  {
    name: "HOME",
    path: "",
  },
  {
    name: "ABOUT",
    path: "about",
  },
  {
    name: "CASE STUDY",
    path: "case-study",
  },
];
const SubMenus = () => {
  return (
    <Box width={"100%"}>
      <Flex justify={"space-evenly"} gap={12}>
        {navLinks.map((el) => (
          <NavLink
            key={el.path}
            to={el.path}
            style={({ isActive }) => (isActive ? activeStyle : {})}
          >
            {el.name}
          </NavLink>
        ))}
      </Flex>
    </Box>
  );
};

export default SubMenus;
