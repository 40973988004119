import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};
export const theme = extendTheme({
  fonts: {
    heading: "League Spartan, sans-serif",
    body: "Rubik, sans-serif",
  },
  config,
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            label: {
              color: "#C4C4C4",
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Button: {
      variants: {
        primary: {
          bgColor: "green.700",
          size: "md",
          color: "darkMode.textColor",
          fontWeight: "700",
          _hover: {
            bgColor: "lightMode.btnBgColor",
          },
          _focus: {
            bgColor: "lightMode.btnBgColor",
          },
          _active: {
            bgColor: "lightMode.btnBgColor",
            outlineWidth: "0px",
            outlineOffSet: "0px",
            borderWidth: "0px",
          },
        },
        blue: {
          bgColor: "lightMode.blue",
          size: "md",
          color: "lightMode.white",
          fontWeight: "700",
          _hover: {
            bgColor: "lightMode.blue",
          },
          _focus: {
            bgColor: "lightMode.blue",
          },
          _active: {
            bgColor: "lightMode.blue",
          },
        },
      },
      defaultProps: {
        variant: "primary",
      },
    },
  },
  colors: {
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
    darkMode: {
      mainBgColor: "#2c2c54",
      secBgColor: "#40407a",
      textColor: "#f3f3f3",
      btnBgColor: "#5f27cd",
      btnTextColor: "#030303",
      navBgColor: "#1C2A35",

      miniBgColor: " #1C2A35",
      inputBgColor: "#1c2b36",

      mainTextColor: "#84C9FB",

      labelTextColor: "#D2D2D240",
      cardBgColor: "#1C2A35",
      labelBgColor: "#1991DD",
    },
    lightMode: {
      mainBgColor: "#dfe4ea",
      secBgColor: "#f5f6fa",
      textColor: "#030303",
      btnBgColor: "#5f27cd",
      btnTextColor: "#f3f3f3",
      navBgColor: "#9c88ff",

      // miniBgColor: " #1C2A35",
      inputBgColor: "white",

      miniBgColor: "#FFFFFF",

      mainTextColor: "#000000",
      labelTextColor: "#00000040",
      cardBgColor: "#FFFFFF",
      labelBgColor: "#7BB4E3",
    },
  },
});
