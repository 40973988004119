import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { navLinks } from "./SubMenus";

const SideMenu = ({ isOpen, onClose }: any) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody mt={8}>
          <Box>
            <Flex
              direction={"column"}
              gap={4}
              w="fit-content"
              borderBottomWidth={""}
            >
              {navLinks.map((el) => (
                <NavLink
                  onClick={onClose}
                  key={el.path}
                  to={el.path}
                  // style={({ isActive }) =>
                  //   isActive
                  //     ? {
                  //         borderBottom: "0px solid transparent",
                  //         ...activeStyle,
                  //       }
                  //     : {}
                  // }
                >
                  {el.name}
                </NavLink>
              ))}
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideMenu;
