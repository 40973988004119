import React, { lazy } from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Route, Routes, useNavigate } from "react-router-dom";
import { RiMenu3Fill } from "react-icons/ri";
import SubMenus from "./components/SubMenus";
import SideMenu from "./components/SideMenu";
import Footer from "./components/Footer";
import { Freelancer } from "./assets";
import "@fontsource/rubik";
import "@fontsource/league-spartan";
import WithSuspense from "./components/WithSuspense";

const HomePage = WithSuspense(lazy(() => import("./pages/HomePage")));
const About = WithSuspense(lazy(() => import("./pages/About")));
const CaseStudy = WithSuspense(lazy(() => import("./pages/CaseStudy")));

export const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.navBgColor"
  );
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  return (
    <Flex flexDir={"column"} height="100vh">
      <Flex
        w="100%"
        justify={"space-between"}
        align="center"
        p={3}
        px={6}
        bgColor={navBgColor}
        position="fixed"
        top={0}
        zIndex="overlay"
        boxShadow={"md"}
      >
        <Flex
          align={"center"}
          gap={2}
          cursor="pointer"
          onClick={() => navigate("/")}
        >
          <Image width={"30px"} src={Freelancer} />
          <Text>Favour Yusuf</Text>
        </Flex>
        {!isMobile && (
          <Box>
            <SubMenus />
          </Box>
        )}

        <Flex gap={4}>
          <ColorModeSwitcher justifySelf="flex-end" />
          {isMobile && (
            <IconButton
              bg={"none"}
              onClick={onOpen}
              variant="ghost"
              aria-label="Harmbuger"
              icon={
                <RiMenu3Fill
                  color={colorMode === "light" ? "#030303" : "#f3f3f3"}
                />
              }
            />
          )}
        </Flex>
      </Flex>
      {isMobile && <SideMenu isOpen={isOpen} onClose={onClose} />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/about" element={<About />} />
      </Routes>

      <Box mt="auto">
        <Footer />
      </Box>
    </Flex>
  );
};
