import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaInstagram, FaLinkedin, FaMedium, FaTwitter } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Freelancer } from "../assets";

const Footer = () => {
  const navBgColor = useColorModeValue(
    "lightMode.secBgColor",
    "darkMode.navBgColor"
  );
  return (
    <Box bgColor={navBgColor} px={[4, 8, 16, 24]} py={8} boxShadow="inner">
      <Flex w="100%" flexDirection={["column"]} alignItems="center">
        <Flex align={"center"} gap={2}>
          <Image width={"30px"} src={Freelancer} />
          <Text>Favour Yusuf</Text>
        </Flex>
        <Text>28, Osapa London, Agbeni, Las Vegas</Text>
        <Flex gap={6} w="100%" justify={"center"} mt={4}>
          <NavLink to={"/"}>HOME</NavLink>
          <NavLink to={"/about"}>ABOUT</NavLink>
          <NavLink to={"/case-study"}>CASE STUDY</NavLink>
        </Flex>
        <Text mt={4} mb={2}>
          Around the world with Favour
        </Text>
        <Flex gap={4}>
          <Link>
            <FaInstagram size={32} />
          </Link>
          <Link>
            <FaTwitter size={32} />
          </Link>
          <Link>
            <FaLinkedin size={32} />
          </Link>
          <Link>
            <FaMedium size={32} />
          </Link>
        </Flex>
      </Flex>
      <Text textAlign={"center"} mt={4}>
        All Rights Reserved {new Date().getFullYear()}
      </Text>
    </Box>
  );
};

export default Footer;
